import React from 'react';
import { graphql } from 'gatsby';
import {
  TRANSPARENT, NEWS, SINGLE_COL, LC_BLACK, NEWS_ITEM,
} from '@constants';
import {
  Layout, ContentLayout, BasicDarkHero, BasicRichText,
} from '@molecules';
import { setSearchParams, getKeyValue } from '@utils';

const NewsItemPageTemplate = ({ location, data, pageContext }) => {
  const {
    site, page, settings, languageLabels,
  } = data;

  const { locale } = pageContext;
  const { mobileDisclaimer, donationDisclaimer, siteTitle } = settings;
  const { social } = getKeyValue(site, 'siteMetadata') || {};
  const {
    metaTitle, metaDescription, metaImage, path, pageContent, disableAlertBar,
    enLanguagePageLinks, pageInOtherLanguages, disableDonationBar,
  } = page || {};
  const {
    title: pageTitle, publicationDate, heroImage, publisher,
    content,
  } = pageContent;
  const { url: metaImageUrl } = getKeyValue(metaImage, 'file') || {};

  const noImage = !heroImage || !heroImage.fluid;

  const searchParams = setSearchParams(location);

  return (
    <Layout
      location={location}
      metaDescription={metaDescription && metaDescription.metaDescription}
      metaUrl={`https://${process.env.GATSBY_HOST_DOMAIN}${path}`}
      metaTitle={`${metaTitle} | ${siteTitle}`}
      metaImageUrl={metaImageUrl}
      fbAppID={social.fbAppID}
      twitter={social.twitter}
      locale={locale}
      navTheme={TRANSPARENT}
      settings={settings}
      navAnimate
      pageInOtherLanguages={pageInOtherLanguages}
      enLanguagePageLinks={enLanguagePageLinks}
      languageLabels={languageLabels}
      disableDonationBar={disableDonationBar}
      disableAlertBar={disableAlertBar}
      searchParams={searchParams}
    >
      <BasicDarkHero
        title={pageTitle}
        publisher={publisher}
        releaseDate={publicationDate}
        heroImage={heroImage}
        pageType={NEWS}
      />
      <ContentLayout layout={`${SINGLE_COL}${noImage ? 'noimage' : ''}`}>
        {content && (
          <BasicRichText
            content={content}
            textWidth={SINGLE_COL}
            color={LC_BLACK}
            pageType={NEWS_ITEM}
            mobileDisclaimer={mobileDisclaimer}
            donationDisclaimer={donationDisclaimer}
          />
        )}
      </ContentLayout>
    </Layout>
  );
};

export default NewsItemPageTemplate;

export const newsItemPageQuery = graphql`
  query NewsItemPageBySlug($slug: String!, $locale: String!) {
    site {
      ...SiteMetadata
    }
    page: contentfulPage(path: { eq: $slug }, language: { eq: $locale }) {
      ...PageMetadata
      pageContent {
        ... on ContentfulPageContentNewsItem {
          title
          id
          publisher
          author
          publicationDate(formatString: "MMM D, YYYY")
          heroImage {
            fluid(quality: 90, maxWidth: 700) {
              ...GatsbyContentfulFluid_withWebp
            }
            file {
              url
            }
          }
          summary {
            raw
          }
          content {
            raw
            references {
              ... on ContentfulPage {
                contentful_id
                path
              }
              ... on ContentfulRedirect {
                contentful_id
                from
                to
              }
              ... on ContentfulComponentImageCollage {
                contentful_id
                id
                internal {
                  type
                }
                imageLayout
                images {
                  fluid(quality: 90, maxWidth: 700) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                  description
                  contentful_id
                }
                caption {
                  raw
                }
              }
              ... on ContentfulAsset {
                contentful_id
                __typename
                file {
                  url
                }
                title
              }
              ... on ContentfulComponentMediaEmbed {
                contentful_id
                id
                internal {
                  type
                }
                mediaTitle
                mediaType
                mediaUid
                imageOrCustomThumbnail {
                  fluid(quality: 90, maxWidth: 1170) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
                mediaDescription {
                  raw
                }
              }
            }
          }
        }
      }
    }
    settings: contentfulGlobalSiteSettings(language: { eq: $locale }) {
      ...GlobalSiteSettings
    }
    languageLabels: contentfulGlobalSiteSettings(language: { eq: "en-US" }) {
      ...LanguageLabels
    }
  }
`;
